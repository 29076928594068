import React, { useState, useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { useAccount } from 'wagmi';
import { database } from '../firebaseConfig';
import { ref, onValue, set, remove, query, limitToLast } from 'firebase/database';
import { FaTrash, FaEdit, FaEraser, FaBan, FaTelegram, FaDiscord } from 'react-icons/fa';

// Import Degen images
import Degen1 from '../images/Degen1.png';
import Degen2 from '../images/Degen2.png';
import Degen3 from '../images/Degen3.png';
import Degen4 from '../images/Degen4.png';
import Degen5 from '../images/Degen5.png';
import Degen6 from '../images/Degen6.png';
import Degen7 from '../images/Degen7.png';
import Degen8 from '../images/Degen8.png';
import Degen9 from '../images/Degen9.png';
import Degen10 from '../images/Degen10.png';
import Degen11 from '../images/Degen11.png';
import Degen12 from '../images/Degen12.png';
import Degen13 from '../images/Degen13.png';
import Degen14 from '../images/Degen14.png';
import Degen15 from '../images/Degen15.png';
import Degen16 from '../images/Degen16.png';
import Degen17 from '../images/Degen17.png';
import Degen18 from '../images/Degen18.png';
import Degen19 from '../images/Degen19.png';
import Degen20 from '../images/Degen20.png';

const degenImages = [
  Degen1, Degen2, Degen3, Degen4, Degen5,
  Degen6, Degen7, Degen8, Degen9, Degen10,
  Degen11, Degen12, Degen13, Degen14, Degen15,
  Degen16, Degen17, Degen18, Degen19, Degen20
];

const adminAddresses = [
  '0x7308BD2ea2bc85B44e44c64B30c14C189F75531d'.toLowerCase(),
  '0x7384e9D49283D1195D1e8FD6515D72d622e65a70'.toLowerCase()
];

// Utility functions
const getAvatarForAddress = (address) => {
  if (!address) return '';
  const index = Math.abs(address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % degenImages.length;
  return degenImages[index];
};

const getDgnNumberForAddress = (address) => {
  if (!address) return 'DGN000';
  if (address.toLowerCase() === adminAddresses[0]) return 'DGN001';
  if (address.toLowerCase() === adminAddresses[1]) return 'DGN002';
  const hash = Math.abs(address.split('').reduce((a, b) => ((a << 5) - a) + b.charCodeAt(0), 0));
  return `DGN${(hash % 1000).toString().padStart(3, '0')}`;
};

const trimAddress = (address) => {
  if (!address) return '0x0000...0000';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const getColorForAddress = (address) => {
  if (!address) return '#000000';
  const hash = Math.abs(address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0));
  return `hsl(${hash % 360}, 70%, 50%)`;
};

// Available reactions
const REACTIONS = {
  GORILLA: '🦍',
  WHALE: '🐳',
  ROCKET: '🚀',
  DIAMOND: '💎',
  FIRE: '🔥',
  BLUE_CIRCLE: '🔵',
  YELLOW_CIRCLE: '🟡',
  BLACK_CIRCLE: '⚫',
  THUMBS_UP: '👍',
  THUMBS_DOWN: '👎',
  LAUGHING: '😂',
  POO: '💩',
  SALUTE: '🫡',
  HUNDRED: '💯',
  HANDSHAKE: '🤝'
};

const TestChat = () => {
  const { address, isConnected } = useAccount();
  const isAdmin = adminAddresses.includes(address?.toLowerCase());

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [editingMessage, setEditingMessage] = useState(null);
  const [showChatEmojiPicker, setShowChatEmojiPicker] = useState(false);
const [showEmojiPicker, setShowEmojiPicker] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const [showReactions, setShowReactions] = useState(null); // Tracks which message's reactions are being shown
  const chatEndRef = useRef(null);

  useEffect(() => {
    const messagesRef = query(ref(database, 'messages'), limitToLast(100));

    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedMessages = Object.values(data);
        setMessages(loadedMessages);
      } else {
        setMessages([]);
      }
    });

    return () => {
      // Cleanup
    };
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    setIsTyping(Boolean(newMessage));
  }, [newMessage]);

  const handleReaction = (messageId, reaction) => {
    if (!isConnected) return;

    const message = messages.find(msg => msg.id === messageId);
    if (!message) return;

    const reactions = message.reactions || {};
    const userReactions = reactions[reaction] || [];
    const userIndex = userReactions.indexOf(address);

    let updatedReactions = { ...reactions };

    if (userIndex >= 0) {
      // Remove reaction
      updatedReactions[reaction] = userReactions.filter(addr => addr !== address);
      if (updatedReactions[reaction].length === 0) {
        delete updatedReactions[reaction];
      }
    } else {
      // Add reaction
      updatedReactions[reaction] = [...(userReactions), address];
    }

    set(ref(database, `messages/${messageId}/reactions`), updatedReactions);
  };

  const handleSendMessage = () => {
    if (!isConnected) {
      alert('Please connect your wallet to send messages.');
      return;
    }

    if (editingMessage) {
      const confirmEdit = window.confirm('Are you sure you want to edit this message?');
      if (confirmEdit) {
        const updatedMessages = messages.map((msg) =>
          msg.id === editingMessage.id ? { ...msg, text: newMessage } : msg
        );
        setMessages(updatedMessages);
        set(ref(database, `messages/${editingMessage.id}`), {
          ...editingMessage,
          text: newMessage,
        });
        setEditingMessage(null);
      }
    } else if (newMessage.trim()) {
      const newId = messages.length > 0 ? messages[messages.length - 1].id + 1 : 1;
      const newMessageObj = {
        id: newId,
        text: newMessage,
        sender: address,
        system: false,
        role: isAdmin ? 'Admin' : 'User',
        replyTo: replyingTo ? {
          id: replyingTo.id,
          text: replyingTo.text,
          sender: replyingTo.sender
        } : null,
        reactions: {}
      };

      set(ref(database, `messages/${newId}`), newMessageObj);
      setMessages([...messages, newMessageObj]);
    }
    setNewMessage('');
    setShowEmojiPicker(false);
    setReplyingTo(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleEmojiSelect = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handleEditMessage = (msg) => {
    const confirmEdit = window.confirm('Are you sure you want to edit this message?');
    if (confirmEdit) {
      setEditingMessage(msg);
      setNewMessage(msg.text);
    }
  };

  const handleDeleteMessage = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this message?');
    if (confirmDelete) {
      const updatedMessages = messages.filter((msg) => msg.id !== id);
      setMessages(updatedMessages);
      remove(ref(database, `messages/${id}`));
    }
  };

  const handleClearChat = () => {
    const firstConfirm = window.confirm('WARNING: Are you sure you want to clear ALL messages? This action cannot be undone.');
    if (firstConfirm) {
      const secondConfirm = window.confirm('FINAL WARNING: This will permanently delete all chat messages. Press OK to proceed.');
      if (secondConfirm) {
        remove(ref(database, 'messages'));
        setMessages([]);
      }
    }
  };

  const handleMuteUser = (userId, duration) => {
    const confirmMute = window.confirm(`Are you sure you want to mute user ${trimAddress(userId)} for ${duration}?`);
    if (confirmMute) {
      alert(`User ${trimAddress(userId)} has been muted for ${duration}.`);
    }
  };

  const handleReply = (msg) => {
    setReplyingTo(msg);
    document.querySelector('textarea').focus();
  };

  const handleCancelReply = () => {
    setReplyingTo(null);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      backgroundColor: '#161A42',
      color: 'white'
    }}>
      {/* Header with Subheader - remains the same */}
      <div style={{
        backgroundColor: '#161A42',
        padding: '10px',
        borderBottom: '1px solid #2A2F50',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '15px'
        }}>
          <a 
            href="https://t.me/yourtelegramlink" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ 
              color: '#1FA2FF', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              transition: 'color 0.2s'
            }}
            onMouseOver={(e) => e.currentTarget.style.color = '#A6FFCB'}
            onMouseOut={(e) => e.currentTarget.style.color = '#1FA2FF'}
          >
            <FaTelegram size={24} />
          </a>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Degen Chat</span>
          <a 
            href="https://discord.gg/yourdiscordlink" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ 
              color: '#1FA2FF', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              transition: 'color 0.2s'
            }}
            onMouseOver={(e) => e.currentTarget.style.color = '#A6FFCB'}
            onMouseOut={(e) => e.currentTarget.style.color = '#1FA2FF'}
          >
            <FaDiscord size={24} />
          </a>
        </div>
        <div style={{
          fontSize: '10px',
          color: '#888',
          textAlign: 'center',
          marginBottom: '8px'
        }}>
          Connect to chat. Support available on TG & Discord.
        </div>
      </div>

      {/* Messages Area */}
      <div style={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: '10px',
        paddingBottom: '60px',
        backgroundColor: '#161A42',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}>
        {messages.map((msg) => (
          <div key={msg.id} style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '10px'
          }}>
            {!msg.system && (
              <div style={{ position: 'relative', marginRight: '10px', flexShrink: 0 }}>
                <img
                  src={getAvatarForAddress(msg.sender)}
                  alt="avatar"
                  style={{ width: '40px', height: '40px', borderRadius: '50%', border: '3px solid #F2C94C', marginTop: '4px' }}
                />
                <div style={{
                  position: 'absolute',
                  bottom: '-10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: getColorForAddress(msg.sender),
                  color: '#ffff',
                  padding: '1px 4px',
                  borderRadius: '10px',
                  fontSize: '8px',
                  fontWeight: '400',
                  whiteSpace: 'nowrap'
                }}>
                  {getDgnNumberForAddress(msg.sender)}
                </div>
              </div>
            )}
            <div style={{ flexGrow: 1 }}>
              {!msg.system && (
                <div style={{ fontSize: '8px', color: '#888', marginBottom: '1px' }}>
                  {adminAddresses.includes(msg.sender.toLowerCase()) ? 'Moderator' : trimAddress(msg.sender)}
                </div>
              )}
              <div style={{
              backgroundColor: msg.system ? '#ff9900' : '#212760',
              borderRadius: '8px',
              padding: '8px 10px',
              position: 'relative'
              }}
              onMouseEnter={() => !showEmojiPicker && setShowReactions(msg.id)}
              onMouseLeave={() => !showEmojiPicker && setShowReactions(null)}
              >
                {msg.replyTo && (
                  <div style={{
                    fontSize: '10px',
                    color: '#888',
                    borderLeft: '2px solid #3461FF',
                    paddingLeft: '8px',
                    marginBottom: '4px',
                    maxHeight: '40px',
                    overflow: 'hidden'
                  }}>
                    <span style={{ color: '#1FA2FF' }}>
                      {adminAddresses.includes(msg.replyTo.sender.toLowerCase()) 
                        ? 'Moderator' 
                        : trimAddress(msg.replyTo.sender)}
                    </span>
                    : {msg.replyTo.text.length > 30 
                        ? msg.replyTo.text.substring(0, 30) + '...' 
                        : msg.replyTo.text}
                  </div>
                )}
                <div style={{ 
                  fontSize: '12px', 
                  fontWeight: 'normal',
                  whiteSpace: 'pre-wrap'
                }}>
                  {msg.text}
                </div>
              
                {/* Admin controls at top */}
                {isAdmin && (
                  <div style={{ position: 'absolute', top: '-15px', right: '10px', display: 'flex', gap: '5px' }}>
                    <FaEdit 
                      title="Edit Message" 
                      onClick={() => handleEditMessage(msg)} 
                      style={{ cursor: 'pointer', color: '#ffcc00', fontSize: '12px' }} 
                    />
                    <FaTrash 
                      title="Delete Message" 
                      onClick={() => handleDeleteMessage(msg.id)} 
                      style={{ cursor: 'pointer', color: 'red', fontSize: '12px' }} 
                    />
                    <FaBan 
                      title="Mute User (1 Day)" 
                      onClick={() => handleMuteUser(msg.sender, '1 day')} 
                      style={{ cursor: 'pointer', color: 'orange', fontSize: '12px' }} 
                    />
                    <FaBan 
                      title="Mute User (1 Week)" 
                      onClick={() => handleMuteUser(msg.sender, '1 week')} 
                      style={{ cursor: 'pointer', color: 'darkred', fontSize: '12px' }} 
                    />
                    <FaEraser 
                      title="Clear Chat" 
                      onClick={handleClearChat} 
                      style={{ cursor: 'pointer', color: '#3461FF', fontSize: '12px' }} 
                    />
                  </div>
                )}
              
                {/* Hover Actions */}
                {showReactions === msg.id && !showEmojiPicker && (
                  <div style={{
                    position: 'absolute',
                    bottom: '2px',
                    right: '2px',
                    display: 'flex',
                    gap: '6px',
                    padding: '4px',
                    borderRadius: '4px',
                    background: 'rgba(33, 39, 96, 0.95)',
                    zIndex: 2
                  }}>
                    <button
                      onClick={() => handleReply(msg)}
                      style={{
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        padding: '2px',
                        fontSize: '14px'
                      }}
                      title="Reply"
                    >
                      ↩️
                    </button>
                    <button
                      onClick={() => setShowEmojiPicker(msg.id)}
                      style={{
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        padding: '2px',
                        fontSize: '14px'
                      }}
                      title="React"
                    >
                      😊
                    </button>
                  </div>
                )}
              
                {/* Display existing reactions */}
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '4px',
                  marginTop: '4px'
                }}>
                  {msg.reactions && Object.entries(msg.reactions).map(([emoji, users]) => (
                    <button
                      key={emoji}
                      onClick={() => handleReaction(msg.id, emoji)}
                      style={{
                        cursor: 'pointer',
                        background: users.includes(address) ? '#3461FF33' : 'none',
                        border: '1px solid #3461FF33',
                        borderRadius: '12px',
                        padding: '2px 6px',
                        fontSize: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        color: 'grey' 

                      }}
                    >
                      {emoji} {users.length}
                    </button>
                  ))}
                </div>
              
                {/* Emoji Picker with Close Button */}
                {showEmojiPicker === msg.id && (
                  <div style={{
                    position: 'absolute',
                    bottom: '100%',
                    right: '0',
                    backgroundColor: '#212760',
                    borderRadius: '8px',
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    zIndex: 1000,
                    marginBottom: '4px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '4px'
                    }}>
                      <span style={{ fontSize: '12px', color: '#888' }}>Select Reaction</span>
                      <button
                        onClick={() => setShowEmojiPicker(null)}
                        style={{
                          cursor: 'pointer',
                          background: 'none',
                          border: 'none',
                          color: '#888',
                          fontSize: '14px',
                          padding: '2px'
                        }}
                      >
                        ✕
                      </button>
                    </div>
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px'
                    }}>
                      {Object.values(REACTIONS).map((emoji) => (
                        <button
                          key={emoji}
                          onClick={() => {
                            handleReaction(msg.id, emoji);
                            setShowEmojiPicker(null);
                          }}
                          style={{
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                            padding: '4px',
                            fontSize: '16px'
                          }}
                        >
                          {emoji}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
                          </div>
                        </div>
                      ))}
                      <div ref={chatEndRef} />
                    </div>
              
                    {/* Input Area */}
                    <div style={{
                      padding: '17px',
                      borderTop: '1px solid #2A2F50',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      position: 'sticky',
                      bottom: '60px',
                      zIndex: 1000,
                      backgroundColor: '#161A42'
                    }}>
                      {replyingTo && (
                        <div style={{
                          padding: '5px 10px',
                          backgroundColor: '#212760',
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          fontSize: '10px'
                        }}>
                          <div>
                            Replying to{' '}
                            <span style={{ color: '#1FA2FF' }}>
                              {adminAddresses.includes(replyingTo.sender.toLowerCase()) 
                                ? 'Moderator' 
                                : trimAddress(replyingTo.sender)}
                            </span>
                            : {replyingTo.text.length > 30 
                                ? replyingTo.text.substring(0, 30) + '...' 
                                : replyingTo.text}
                          </div>
                          <button
                            onClick={handleCancelReply}
                            style={{
                              background: 'none',
                              border: 'none',
                              color: '#888',
                              cursor: 'pointer',
                              padding: '0 5px'
                            }}
                          >
                            ✕
                          </button>
                        </div>
                      )}
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <textarea
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                          onKeyDown={handleKeyPress}
                          placeholder={!isConnected ? 'Connect Wallet' : 'Type your message...'}
                          maxLength={isAdmin ? undefined : 180}
                          style={{
                            flexGrow: 1,
                            padding: '6px 10px',
                            height: '28px',
                            minHeight: '28px',
                            maxHeight: '100px',
                            borderRadius: '5px',
                            border: 'none',
                            outline: 'none',
                            color: 'white',
                            backgroundColor: '#212760',
                            fontSize: '12px',
                            resize: 'vertical',
                            fontFamily: 'inherit'
                          }}
                          disabled={!isConnected}
                        />
                        <button 
                          onClick={() => setShowChatEmojiPicker(!showChatEmojiPicker)} 
                          style={{
                            padding: '6px 10px',
                            height: '28px',
                            backgroundColor: '#3461FF',
                            color: 'white',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          disabled={!isConnected}
                        >
                          😊
                        </button>
                        <button 
                          onClick={handleSendMessage} 
                          style={{
                            padding: '6px 10px',
                            height: '28px',
                            backgroundColor: '#3461FF',
                            color: 'white',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '12px'
                          }}
                          disabled={!isConnected}
                        >
                          {editingMessage ? 'Update' : 'Send'}
                        </button>
                      </div>
                      {showChatEmojiPicker && (
                        <div style={{
                          position: 'absolute',
                          bottom: '60px',
                          left: '0',
                          zIndex: 1000
                        }}>
                          <EmojiPicker
                            onEmojiClick={handleEmojiSelect}
                            theme="dark"
                            pickerStyle={{ fontSize: '10px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              };
              
              export default TestChat;